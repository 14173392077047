.insurance-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../assets/images/banner8.jpg");
  background-size: cover;
  background-position: center;
}

.insurance-section h3 {
  text-align: center;
  font-size: 1.7em;
  margin-top: 0.75em;
  color: $primary_color; 
}
.insurance-type {
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.insurance-section p{
  justify-content: center;
}
.residential-bg {
  background-image: url("./../assets/images/banner7.jpg");
  background-size: cover;
  background-position: center;
}
.commercial-bg {
  background-image: url("./../assets/images/banner9.jpg");
  background-size: cover;
  background-position: center;
}

.button{
  max-width: 250px;
  font-size: 16px;
}