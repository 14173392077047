.about-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../assets/images/banner-about-min.jpg");
  background-size: cover;
}

.about-owner {
  overflow: auto;
}

.about-company {
  margin: 0 auto;
}

.about-sidebar {
  height: fit-content !important;
}

.accordion-title {
  position: relative;
  display: block;
  padding: 1.25rem 4rem 1.25rem 1.25rem;
  border: 1px solid #e6e6e6;
  border-bottom: 0;
  font-size: 18px;
  line-height: 1;
  color: #0a0a0a;
}

.accordion-content p {padding:0px; margin:0px;}

@media screen and (max-width: 1024px) {
  .about-sidebar img{
    display: none;
  }
}