.review-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../assets/images/banner-reviews-min.jpg");
  background-size: cover;
  background-position: top;
}
.review-section {
  h4 {
    font-family: $heading-font;
    font-size: 1.5em;
  }
}

.review {
  margin-bottom: 2em;
  .google {
    color: #1F3450;
    font-weight:Bold;
  }
  .facebook {
    color: #3b5998;
  }
}

.reviews-sidebar {
  h3 {
    margin-top: 1em;
  }
}

.review-icons {
  gap: 1rem;
  .circle {
    color: rgb(200, 200, 200);
    border: solid 1px rgb(200, 200, 200);
  }
}

@media only screen and (max-width: 640px) {
  .review-icons {
    justify-content: space-around;
  }
}
