.footer {
  background-color: $primary_color;
  color:            $white;
  font-size:        16px;
  
  a {
    color:          $white;
    font-weight:    900;
  &:hover{
    color:          $highlight;
  }
  }
}

.footer ul {
  list-style:       none;
  margin-left:      0;
}

.insurance-anchors {
  li {
    border-right:   2px solid $highlight;
    padding:        3px 6px;
  }
  li:first-child {
    padding-left:   inherit;
  }
  li:last-child {
    border-right:   none;
  }
}

.footer h3 {
  font-family:      $heading-font; 
  text-transform:   none;
}

.footer-icons {
  flex-wrap:        wrap;

  span {margin-right:1em;}
} 

.circle {
  padding:          1.2em;
  border:           solid 1px #ffffffbe;
  border-radius:    50%;
  width:            1em;
  height:           1em;
  margin:           0;
  color:            #ffffff;
  transition:       0.3s ease;
  margin-bottom:    1rem;
}

.circle:hover {
  &#facebook {
    background-color: #3b5998;
    border-color: #3b5998;
    color: #fff;
  }
  &#twitter {
    background-color: #00aced;
    border-color: #00aced;
    color: #fff;
  }
  &#google {
    background-color: #dd4b39;
    border-color: #dd4b39;
    color: #fff;
  }
  &#instagram {
    background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
    color: #fff;
  }
  &#linkedin {
    background-color: #007bb6;
    border-color: #007bb6;
    color: #fff;
  }
  &#yelp {
    background-color: #d32323;
    border-color: #d32323;
    color: #fff;
  }
}

.social-center {
  line-height: inherit;
}


@media only screen and (max-width: 1024px) {
  .footer-icons {
    justify-content: flex-start;
    a {
      margin-right: 1em;
    }
  }
}



