.banner-section {
  height:           750px;
  width:            100%;
  // background-image: url("./../assets/images/banner-home.jpg");
  background:       linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./../assets/images/banner-min.jpg");
  background-size:  cover;
  color:            #ffffff;
  display:          flex;
  align-items:      center;
  padding:          2rem;

  p {
    font-size:24px;
    padding-bottom:1rem;
  }
}

.banner-info {
  display: flex;



  @include breakpoint (medium down) {
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    
    h1 {
      padding-bottom: 3rem;
    }
  } 
  
}

.button-container {
  text-shadow: none;
}

.banner h1 {
  @include breakpoint (medium down) {
    text-align: center;
  }
}