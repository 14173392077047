.contact-banner {
  // background-image: url("./../assets/images/banner-contact.jpg");
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../assets/images/banner-contact-min.jpg");
  background-size: cover;
  background-position: center;
}

.contact-info-block {
  margin-bottom: 1px;
  h3 {
    font-size: 1.75em;
  }
  a {
    color: #0a0a0a; 
    font-weight: 100;
  }
}

.contact-form {
  textarea {
    resize: none;
  }
}

.map-section {
  margin: 2em 0 0 0;
}

@media only screen and (min-width: 1024px) {
  #address,
  #phone {
    margin: 0;
  }
}

@media only screen and (max-width: 640px) {
  #address,
  #phone {
    margin: 0;
  }
}

.contact-form label {
  font-size: .95em; 
}

#phone a:hover {
  color: #368acf;
}

#phone a{
  color: #2A4F6D;
}

@media screen and (max-width: 630px) {
  .contact-info-block {
    text-align: center; 
  }

  .contact-info-block .cell {
    padding-top: 20px; 
  }
}