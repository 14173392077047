.header {
  background-color:   $white;
  padding:            1em 0;
  color:              $primary_color;
  a {
    text-decoration:  none;
    color:            #333333;
    &:hover{
      color: $highlight;
    }
    &:active{
      color: #3C599B;;
    }
  }
  text-transform:     uppercase;
}



.mobile-nav {
  display: none !important;
  
  .submenu a {
    color: $white;
    &:active,
    &:focus,
    &:hover {
      color: #000 !important;
    }
  }

  @include breakpoint(large down) {
    display: block !important;
  }

  .title-bar { 
    @include breakpoint (medium down) {
      padding: 1em;
    }
    
    button  {
      background-image: none;
      padding: 0;
      margin: 0 1rem;
    }
  }  
}

.accordion-menu a {
  color: #fff;
  &:active,
  &:hover {
    color: $highlight;
  } 
}


.mobile-nav img {
  opacity:                      0;
  animation:                    fadeInFromTop ease 2s;
  animation-iteration-count:    1;
  animation-fill-mode:          forwards;
  animation-delay:              0.5s;
  
  
  @include breakpoint (large up ) {
    padding:                    2rem;   
  }
}

nav {
  @include breakpoint(large down) {
    display: none;
  }

  padding: 20px 0px;
  background: #fff;
  color: #000;

  .desktop-nav-ul {
    display: flex;
    justify-content: flex-end;

    ul > li > a {
      color:            #333333;
      font-size:        15px;
      font-weight:      bold;
      
      &:last-child {
        padding-right: 0px !important;
      }
      &:hover{
        color: $highlight;
    }

    }
    
    .is-dropdown-submenu > li > a {
      color:            #000;
      
      &:hover {
        color:          $primary_color;
        font-weight:    bold;
      }
    }
    
    .nav__submenu {
      list-style-type:  none;
      background:       black;
      border:           none;
    }
  }
}


.link--blog {
 // color: #e4d096;
}

@keyframes fadeInFromTop {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.logo {
  opacity: 0;
  justify-content: flex-start;
  align-items: center;
  animation: fadeInFromTop ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  img {
    min-width: 150px;
  }
}

.call-office {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  i {
    padding: 0.4em;
    color: #FFFFFF;
    background-color: #3C599B;
    border-radius: 50%;
    margin-right: 0.27em;
    font-size: 1.25em;
  }
}

.call-text {
  letter-spacing: 0.025em;
  h5 {
    margin-bottom: auto;
    font-weight: bold;
    font-size: 1em;
    text-transform: none;
    color: #333333;
  }
  a {
    display: block;
    text-align: center;
    color: #3C599B;
    text-transform: none;
    &:hover {
      font-weight: 600;
    }
  }
}

.dropdown.menu>li.is-dropdown-submenu-parent>a {
    position: relative;
    padding-right: 1.5rem; 
  }
  
.dropdown.menu > li.is-active > a {
  color: $highlight;
  font-weight: bold;
}

.is-accordion-submenu-item {
  color: $white;
}

.active {
  color: #3C599B !important;
  font-weight: bold;
}


