/************************  VARIABLES *************************/

$main_font:         "Open Sans", sans-serif;  
$heading-font:      'Nunito', sans-serif;
$base_font_size:    18px;
$primary_color:     #3C5A9B;
$highlight:         #368acf;
$secondary_color:   #2A4F6D;  
$white:             #FFFFFF; 

/*************************  GLOBAL *************************/
.grid-container, hr {max-width:90rem;} 
         

html {    
  scroll-behavior: smooth;
}

body {
  font-family: $main_font;
  font-size: $base_font_size;
  font-weight: 300;
  transition: background-color 0.3s ease;
}
 
a {
  color: $secondary_color;
  font-weight:bold;
  cursor: pointer;

  &:hover {
    // font-weight: bold !important;
    color: #FFFFFF;
  }

/*
  &::after {
    content: attr(data-text) !important;
    height: 0;
    visibility: hidden;
    font-weight: bold;
    display: block;
    width: auto !important;
    border-style: none !important;
    position: relative !important;
  }
  */
}

ul {margin-left:2em;}

.call-text a {text-align:left;}

button, .button,
[type="submit"] {
  background-image: linear-gradient(
    to right,
    $secondary_color,
    $primary_color,
    $secondary_color
  );
  background-size: 185% 100%;
  color: #FFFFFF;
  padding: 1.2em 1.8em;
  border: none;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  margin-bottom: 1em;
  margin-right: 2em;
  border-radius: 4px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  text-transform: uppercase;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

button,
.button,
[type="submit"] {
  &:active,
  &:focus,
  &:hover {
    transform: translateY(-5px);
    color: #FFFFFF;
  }
}

.secondary-btn {
  background-image: none;
  background-color: #fff;
  color: $primary_color;
  transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  &:active,
  &:focus,
  &:hover {
    background-color: $primary_color;
    color: #FFFFFF;
    transform: translateY(-5px);
  }
}

h1, h2
h3 {
  font-family: $heading-font;
}


h5,
h6 {
  font-family: $main_font;
  text-transform: uppercase;
  font-weight: 600;
}

.subheading {
  font-family: $main_font;
  text-transform: uppercase;
  font-weight: 600;
  color: $highlight;
}


.flex-container {
  display: flex;
  width: 100%; 
  max-width: 1200px;
  margin: 0 auto; 
}  

.flex-center {
  justify-content: center;    
  align-items: center;
}

.flex-center-column {
  justify-content: center;
  align-items: center;   
  flex-direction: column;
}
h1,
h3.dk_sectionheading { 
  font-size: 3rem;
  font-weight: 300;
  font-family: $heading-font;
  text-transform: none;
}

h2 {
  font-size: 2rem;
  color: #000;
  margin-bottom: 1rem;
}

section {
  padding: 4em 0;
}

.p20 {padding-bottom:20px;}
.mt {margin-top:2em;}

.sidebar a {color: $primary_color;}
.sidebar a:hover {color:$secondary_color;}

.sidebar, .reviews-sidebar {
  padding-left:40px;

  @include breakpoint (large down) {
    padding-left: 0;
  }

}

.banner {
  background-size: cover;
  position: relative;
  height: 350px;
  display: flex;
  align-items: center;
  color: #fff;
  text-shadow: 2px 2px 4px #000000;
}

.section-heading{
  margin-bottom: 25px;
}

.section{
  padding-top: 2em;
  padding-bottom: 2em;
}
.margin-bottom {
  margin-bottom: 60px;
}

h1, h3.dk_sectionheading {
  @include breakpoint (large down) {
    font-size: 2.75rem;
  }
}

.insurance-icon {
  @include breakpoint (large down) {
    font-size: 1.25rem !important;
  }
}

.fa-paint-roller{
  color: $secondary_color;
}

/************************* BLOG ***************************/
article {
  padding: 2rem;    
}  

/*************************  ABOUT *************************/

.about-content {
  width: 80%;
  h1 {
    font-size: 2.75em;
  }
}

.about-owner {
  padding-top:40px;
}

.about-team-info{
  background-color: #eae9e5;
}

.about-content.flex-container {
  padding-left: 0.9375rem;
  flex-direction: column;
  justify-content: center;
}

.action-buttons {
  margin: 0;
  margin-top: 2em;
  justify-content: space-between;
  a {
    display: inline-block;
    color:#FFF!important;
    button {
      border-radius: 5px;
      width: max-content;
    }
    a:hover {color:#FFF!important;}
  }
}

/*************************  QUALITY INSURANCE  *************************/

.quality-section {
  /*background-image: url("./../assets/images/off-background.jpg");
  background-size: cover;*/
  background-color: #EFEFEF;
  color: #333333;
  display: flex;
}

.quality-insurance {

  a.button {margin: 1em 0 0 0;}

  .content {
    padding: 2em 0;
  }

  h3 {
    font-size: 2em;
    text-align: center;
    font-family: $heading-font;
    text-transform: none;
  }

  h5 {
    text-transform: none;
    font-weight: bold;
  }
}

.insurance-icon {
  font-size: 1em;
  background-color: $white;
  padding: 0.4em;
  border-radius: 50%;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $primary_color;
}

/************************ RESIDENTIAL MASONRY GALLERY ****************/

.masonry-css {
  flex-wrap: wrap;
  

  @include breakpoint(400px) {
    column-count: 2;
  }

  @include breakpoint(600px) {
    column-count: 3;
  }

  @include breakpoint(800px) {
    column-count: 4;
  }

  @include breakpoint(1100px) {
    column-count: 5;
  } 
}

.masonry-css-item {
  break-inside: avoid;
  /*margin-bottom: $masonry-css-column-gap;*/
}





/********************** PHOTO GALLERIES GENERAL*******************/
.image-container img {margin-bottom:1.25em;}


/*************************  REVIEWS Section  *************************/

.reviews-section {
  background-image: url("./../assets/images/testimonial-bkgd-min.jpg");
  box-shadow: inset 2000px 0 0 0 rgba(0, 0, 0, 0.5);
  background-size: cover; 
  background-position: center; 
  position: relative;
  color: #ffff;

  .reviews-desc {
    margin-top: 4em; 
    @include breakpoint(medium down) {
      text-align: center;
    }
  }
}

.reviews-section h3 {
  text-transform: none;
  font-family: $heading-font;
}

.card {
  background-color: #fff;
  color: #a3a3a3;
  border-radius: 5px;
  padding: 2em 2em;
  position: relative;

  .quotes {
    position: absolute;
    font-size: 1.2em;
    top: 0;
    right: 0;
    padding: 0.5em 0.5em 1.2em 1.2em;
    border-radius: 0 0 0 100px;
    background-color: #1e136f10;
    color: $primary_color;
  }

  .name {
    color: $primary_color;
    font-weight: bold;
    text-transform: none;
    font-size: 1.25em;
    margin: 0;
  }
}

.reviewer {
  margin-bottom: 1em;
}

.card p {
  margin: 0;
}

.review-link {
  color: $secondary_color;
  padding-top: 1rem;
  a {
    color: $highlight;
    font-weight: 600;
  }
}

@media only screen and (max-width: 640px) {
  .owner-image {
    text-align: center;
    margin-bottom: 2em;
  }



  .quality-insurance h3 {
    margin-bottom: 1em;
  }

  .reviews-desc {
    padding: 0 10px;
  }
}
article {
  padding:   2rem; 
}

@media only screen and (max-width: 420px) {
  .banner-section h1 {
    font-size: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .commercial-section .sidebar {
    padding-top: 3em;
  }
}

@media screen and (max-width: 1024px) {
  .personal-section .sidebar {
    padding-top: 3em;
  }
}

.about-content {
  display: flex;
  flex-direction: column;
}

.owner-image {
  align-self: flex-start;
  flex-shrink: 0;
}

@media screen and (max-width: 824px) and (min-width: 774px) {
  .owner-image {
  min-width: 280px;
  }
}


@media screen and (min-width: 825px){
  .owner-image{
   min-width: 300px;
  }
}

@media screen and (max-width: 775px) and (min-width: 640px) {
  .owner-image {
   min-width: 270px;
  }
}

.privacy:hover{
  color: $primary_color;
}