.request-banner {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url("./../assets/images/banner-request-min.jpg");
    background-size: cover;
    background-position: center;
  }


.contact-info-block {
  margin-bottom: 25px;
  h3 {
    font-size: 1.75em;
  }
  a {
    color: #0a0a0a; 
    font-weight: 100;
  }
}

.contact-form {
  textarea {
    resize: none;
  }
}

@media only screen and (min-width: 1024px) {
  #address, 
  #phone {
    margin: 0;
  }
}

@media only screen and (max-width: 640px) {
  #address, 
  #phone {
    margin: 0;
  } 
}


.contact-box{
  margin-top: 20px;
}

.request-quote label {
  font-size: .95em; 
}

.contact-box input[type="checkbox"] {
  margin-left: 1em;
}

@media screen and (max-width: 1024px) {
  .contact-info img {
    display: none;
  }
}