.residential-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../assets/images/banner-residential-min.jpg");

  background-size: cover;
  background-position: bottom;
}

.commercial-banner {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("./../assets/images/banner-commercial-min.jpg");

  background-size: cover;
  background-position: center;
}

.sidebar {
  h3 {
    margin-top: 1em;
  }
}
